import { css } from '@emotion/react';
import { color } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { ColorInput } from 'modules/app/components/shared/ImageGridLayout';
import { wrappers } from 'style/variables';

export const wrapper = css`
  max-width: ${wrappers.page};
  margin: 0 auto;
  padding: 0 var(--spacing-xlrg);
  box-sizing: content-box;
`;

export const grid = css`
  display: grid;
  row-gap: var(--spacing-xhuge);

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-xxxlrg);
    row-gap: calc(var(--unit) * 30);
  }
`;

const featuresListGrid = css`
  display: grid;
  row-gap: var(--spacing-xhuge);

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--spacing-xxxlrg);
  }

  @media ${breakpoints.large} {
    grid-template-columns: repeat(12, 1fr);
    row-gap: var(--spacing-huge);
  }
`;

export const features = (bgColor?: ColorInput) => css`
  padding-top: calc(var(--unit) * 20);
  padding-bottom: calc(var(--unit) * 20);

  background-color: ${bgColor ? color(...bgColor) : 'transparent'};
`;

export const heading = css`
  @media ${breakpoints.medium} {
    grid-column: 1 / span 6;
  }
`;

export const featuresList = (theme = 'dark') => css`
  padding-left: 0;

  grid-column: 1 / -1;

  ${featuresListGrid};

  li {
    color: ${theme === 'dark' ? color('text', 1) : color('text', 6)};

    @media ${breakpoints.large} {
      grid-column-start: span 3;
      grid-column-end: span 3;
    }
  }
`;
